import { toast } from 'react-toastify';
import { LibraryItemReference } from '../constants/library';
import isFriendlyHttpError from '../utils/isFriendlyHttpError';
import LibraryHttpService from './http/library-http';

export const saveLibrary = async ({
    setLoading,
    getValues,
    onHide,
    onSave,
    id,
}: any) => {
    try {
        setLoading(true);

        const params = getValues();

        params.type = params.type.value;

        if (!id) {
            await LibraryHttpService.storeLibrary(params);
        } else {
            await LibraryHttpService.updateLibrary(id, params);
        }

        toast.success(
            id
                ? `Biblioteca editada com sucesso`
                : `Biblioteca cadastrada com sucesso`,
        );

        onHide();
        onSave();
    } catch (err) {
        const error = err as any;

        if (isFriendlyHttpError(error)) {
            toast.error(error.message);
            return;
        }

        toast.error('Erro ao salvar biblioteca');
    } finally {
        setLoading(false);
    }
};

export const saveItem = async ({
    setLoading,
    getValues,
    onHide,
    onSave,
    form,
    id,
}: any) => {
    try {
        setLoading(true);

        const params = getValues();

        params.libraryId = params.libraryId ? params.libraryId.value : null;
        params.libraryModuleId = params.libraryModuleId
            ? params.libraryModuleId.value
            : null;
        params.libraryBrandId = params.libraryBrandId
            ? params.libraryBrandId.value
            : null;
        params.libraryBrandCategoryId = params.libraryBrandCategoryId
            ? params.libraryBrandCategoryId.value
            : null;

        if (form && form.get('file')) {
            let path = 'support';

            if (params.reference === LibraryItemReference.Video) {
                path = `${path}/gallery`;
            }

            if (params.reference === LibraryItemReference.Documentation) {
                if (
                    params.libraryModuleId &&
                    !params.libraryBrandId &&
                    !params.libraryBrandCategoryId
                ) {
                    path = `${path}/modules`;
                } else if (params.libraryBrandId) {
                    path = `${path}/brands`;
                } else if (params.libraryBrandCategoryId) {
                    path = `${path}/categories`;
                } else {
                    path = `${path}/others`;
                }
            }

            const formData = new FormData();

            formData.append('file', form.get('file'));
            formData.append('path', path);

            const { data } = await LibraryHttpService.storeFiles(formData);

            params.file = data;
            params.fileId = data.id;
        }

        if (!id) {
            await LibraryHttpService.storeItem(params);
        } else {
            await LibraryHttpService.updateItem(id, params);
        }

        toast.success(
            id ? `Item editado com sucesso!` : `Item cadastrado com sucesso!`,
        );

        onHide();
        onSave();
    } catch (err) {
        const error = err as any;

        if (isFriendlyHttpError(error)) {
            toast.error(error.message);
            return;
        }

        toast.error('Erro ao salvar item');
    } finally {
        setLoading(false);
    }
};

export const saveModule = async ({
    setLoading,
    getValues,
    onHide,
    onSave,
    id,
}: any) => {
    try {
        setLoading(true);

        const params = getValues();

        if (!id) {
            await LibraryHttpService.storeModule(params);
        } else {
            await LibraryHttpService.updateModule(id, params);
        }

        toast.success(
            id
                ? `Módulo editado com sucesso!`
                : `Módulo cadastrado com sucesso!`,
        );

        onHide();
        onSave();
    } catch (err) {
        const error = err as any;

        if (isFriendlyHttpError(error)) {
            toast.error(error.message);
            return;
        }

        toast.error('Erro ao salvar módulo');
    } finally {
        setLoading(false);
    }
};

export const saveBrand = async ({
    setLoading,
    getValues,
    onHide,
    onSave,
    id,
}: any) => {
    try {
        setLoading(true);

        const params = getValues();

        if (!id) {
            await LibraryHttpService.storeBrand(params);
        } else {
            await LibraryHttpService.updateBrand(id, params);
        }

        toast.success(
            id ? `Marca editada com sucesso!` : `Marca cadastrada com sucesso!`,
        );

        onHide();
        onSave();
    } catch (err) {
        const error = err as any;

        if (isFriendlyHttpError(error)) {
            toast.error(error.message);
            return;
        }

        toast.error('Erro ao salvar marca');
    } finally {
        setLoading(false);
    }
};

export const saveCategory = async ({
    setLoading,
    getValues,
    onHide,
    onSave,
    id,
}: any) => {
    try {
        setLoading(true);

        const params = getValues();

        params.libraryBrandId = params.libraryBrandId.value;

        if (!id) {
            await LibraryHttpService.storeBrandCategory(params);
        } else {
            await LibraryHttpService.updateBrandCategory(id, params);
        }

        toast.success(
            id
                ? `Categoria editada com sucesso!`
                : `Categoria cadastrada com sucesso!`,
        );

        onHide();
        onSave();
    } catch (err) {
        const error = err as any;

        if (isFriendlyHttpError(error)) {
            toast.error(error.message);
            return;
        }

        toast.error('Erro ao salvar categoria');
    } finally {
        setLoading(false);
    }
};
