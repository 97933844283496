import RequestV2 from './request-v2';

export default class ContactHttpService {
    private static uri = 'contacts';

    private static uriV2 = 'contact';

    public static create(contact: {}) {
        return RequestV2.post(`${this.uriV2}`, contact);
    }

    public static readMany(params: {
        skip?: number;
        take?: number;
        term?: string;
    }) {
        return RequestV2.get(`${this.uriV2}`, params);
    }

    public static update(id: number, contact: {}) {
        return RequestV2.put(`${this.uriV2}/${id}`, contact);
    }

    public static delete(id: number) {
        return RequestV2.del(`${this.uriV2}/${id}`);
    }
}
