import React, { useEffect, useState } from 'react';
import {
    Button,
    Col,
    Dropdown,
    Form,
    InputGroup,
    Row,
    Table,
} from 'react-bootstrap';
import Highlighter from 'react-highlight-words';
import { Controller, useForm } from 'react-hook-form';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { toast } from 'react-toastify';
import { ReactComponent as ReactMoreIcon } from '../../../assets/icons/more.svg';
import { Branch } from '../../../constants/configuration';
import NotificationIcon from '../../../constants/notificationIcon';
import { rowsPerPage } from '../../../constants/pagination';
import BranchHttpService from '../../../services/http/branch-http';
import { StyledPageTitle } from '../../../styles/pageTitle';
import ConfirmationDialog from '../../../utils/ConfirmationDialog';
import formatMask from '../../../utils/fotmatMask';
import BranchModal from './BranchModal';
import { StyledTh, StyledBadge, StyledText } from './styles';

interface ModalRegisterProps {
    data?: any;
    show?: boolean;
    edit?: boolean;
}

function BranchesData() {
    const [page, setPage] = useState(1);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [term, setTerm] = useState('');
    const [loading, setLoading] = useState(false);

    const [branches, setBranches] = useState<Branch[]>([]);

    const [modalRegister, setModalRegister] = useState<ModalRegisterProps>({});

    const [selectedBranchId, setSelectedBranchId] = useState<number | null>(
        null,
    );

    const [showRemoveBranchModal, setShowRemoveBranchModal] =
        useState<boolean>(false);

    async function loadBranches(
        searchTerm: string = '',
        searchPage?: number,
    ): Promise<void> {
        setLoading(true);

        const params = {
            term: searchTerm,
            skip: searchPage || page,
            take: rowsPerPage,
        };

        try {
            const { data } = await BranchHttpService.readMany(params);

            const totalItems = data.total ? data.total : 0;

            setHasNextPage(branches.length < totalItems);
            setBranches((oldBranches: any) => [...oldBranches, ...data.data]);
            setTerm(searchTerm);
        } catch (err) {
            setHasNextPage(false);
        } finally {
            setLoading(false);
        }
    }

    function handleNewBranch() {
        setModalRegister({ show: true });
    }

    function handleStoreBranch(item: Branch) {
        setModalRegister({
            show: true,
            data: branches.find((branch: Branch) => branch.id === item.id),
            edit: true,
        });
    }

    const handleRemoveBranch = async () => {
        setLoading(true);

        try {
            if (!selectedBranchId) {
                throw new Error('Filial não encontrada para exclusão');
            }
            await BranchHttpService.delete(selectedBranchId);

            toast.success('Filial removida com sucesso');
            setBranches((oldBranches) =>
                oldBranches.filter((b) => b.id !== selectedBranchId),
            );
        } catch (err: any) {
            toast.error(err.message);
        } finally {
            setLoading(false);
        }
    };

    const { control, handleSubmit } = useForm();

    const onSubmit = (data: any) => {
        setBranches([]);
        setPage(1);
        loadBranches(data.term, 1);
    };

    const infiniteRef: any = useInfiniteScroll({
        loading,
        hasNextPage,
        // eslint-disable-next-line no-return-assign, no-param-reassign
        onLoadMore: () => setPage((p) => (p += 1)),
    });

    useEffect(() => {
        loadBranches('', 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (page === 1) {
            return;
        }

        loadBranches(term, page);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    return (
        <>
            <Row>
                <Col>
                    <StyledPageTitle className="mt-2">Filiais</StyledPageTitle>
                </Col>
                <Col>
                    <Button
                        className="float-right w-25"
                        // eslint-disable-next-line react/jsx-no-bind
                        onClick={handleNewBranch}
                    >
                        Nova filial
                    </Button>
                </Col>
            </Row>
            <Row className="mt-2">
                <Form
                    style={{ width: '100%' }}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                <i className="fas fa-search" />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Controller
                            control={control}
                            name="term"
                            render={({ onChange, value }) => (
                                <Form.Control
                                    type="text"
                                    placeholder="Digite aqui o que procura..."
                                    onChange={onChange}
                                    value={value}
                                />
                            )}
                        />
                    </InputGroup>
                </Form>
            </Row>
            <Row>
                <Table
                    ref={infiniteRef}
                    bordered
                    hover
                    size="sm"
                    className="text-center"
                >
                    <thead>
                        <tr>
                            <StyledTh>NOME</StyledTh>
                            <StyledTh>CNPJ</StyledTh>
                            <StyledTh>ESTADOS</StyledTh>
                            <StyledTh>CÓDIGO DA EMPRESA</StyledTh>
                            <StyledTh>CÓDIGO DA FILIAL</StyledTh>
                            <StyledTh />
                        </tr>
                    </thead>
                    <tbody>
                        {branches.map((item: Branch) => (
                            <tr key={item.id}>
                                <td>{item.name}</td>
                                <td>
                                    {formatMask(
                                        item.cnpj || '',
                                        '##.###.###/####-##',
                                    )}
                                </td>
                                <td>
                                    {item?.states ? (
                                        item.states.split(',').map((state) => (
                                            <StyledBadge
                                                variant="light"
                                                key={state}
                                            >
                                                <Highlighter
                                                    autoEscape
                                                    searchWords={[]}
                                                    highlightClassName="highlight-term"
                                                    textToHighlight={state}
                                                />
                                            </StyledBadge>
                                        ))
                                    ) : (
                                        <StyledText>
                                            Nenhum estado associado
                                        </StyledText>
                                    )}
                                </td>
                                <td>{item.companyCode}</td>
                                <td>{item.branchCode}</td>
                                <td>
                                    <Dropdown key="left">
                                        <Dropdown.Toggle
                                            bsPrefix="nexen"
                                            className="mt-n3 mb-n3"
                                            as={Button}
                                            variant="text"
                                        >
                                            <ReactMoreIcon
                                                fill="#bdbdbd"
                                                width="10"
                                                height="20"
                                            />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                onClick={() => {
                                                    handleStoreBranch(item);
                                                }}
                                            >
                                                Editar
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => {
                                                    if (!item?.id) return;

                                                    setSelectedBranchId(
                                                        item?.id,
                                                    );
                                                    setShowRemoveBranchModal(
                                                        true,
                                                    );
                                                }}
                                            >
                                                Remover
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                {branches.length === 0 && term.length === 0 && !loading && (
                    <Col md={12} className="text-center">
                        <strong style={{ color: '#adadad' }}>
                            Sem filiais para carregar
                        </strong>
                    </Col>
                )}

                {branches.length === 0 && term.length > 0 && !loading && (
                    <Col md={12} className="text-center">
                        <strong style={{ color: '#adadad' }}>
                            Nenhuma filial &apos;{term}&apos; encontrada
                        </strong>
                    </Col>
                )}
            </Row>

            {modalRegister && (
                <BranchModal
                    data={modalRegister.data}
                    show={modalRegister.show}
                    edit={modalRegister.edit}
                    onHide={() => {
                        setModalRegister({});
                    }}
                    onSave={(data) => {
                        let newBranches = [...branches];
                        const branchIndex = branches.findIndex(
                            (branch: Branch) => branch.id === data.id,
                        );

                        if (branchIndex >= 0) {
                            newBranches[branchIndex] = data;
                        } else {
                            newBranches = newBranches.concat(data);
                        }

                        setBranches(newBranches);
                        setModalRegister({});
                    }}
                />
            )}

            {showRemoveBranchModal && (
                <ConfirmationDialog
                    show={showRemoveBranchModal}
                    onHide={() => {
                        setShowRemoveBranchModal(false);
                        setSelectedBranchId(null);
                    }}
                    onConfirm={handleRemoveBranch}
                    icon={NotificationIcon.Warning}
                    title="Excluir filial"
                    text="Deseja excluir a filial selecionada?"
                />
            )}
        </>
    );
}

export default BranchesData;
