import Request from './request';
import RequestV2 from './request-v2';

export default class PointHttpService {
    private static uri = 'points';

    private static uriV2 = 'point';

    public static getPointsSeasons() {
        return RequestV2.get(`${this.uriV2}/season`);
    }

    public static savePointsSeason(season: any) {
        return RequestV2.post(`${this.uriV2}/season`, season);
    }

    public static findPointsSeasonById(id: number) {
        return Request.get(`${this.uri}/getPointSeason/${id}`);
    }

    public static editPointsSeason(id: number, season: any) {
        return RequestV2.put(`${this.uriV2}/season/${id}`, season);
    }

    public static deletePointsSeason(id?: number) {
        return RequestV2.del(`${this.uriV2}/season/${id}`);
    }
}
