import { RequestParams } from '../../components/Configuration/BranchesData/BranchModal';
import RequestV2 from './request-v2';

export default class BranchHttpService {
    private static uriV2 = 'branch';

    public static create(branch: RequestParams) {
        return RequestV2.post(`${this.uriV2}`, branch);
    }

    public static readMany(params: {
        skip?: number;
        take?: number;
        term?: string;
        cnpj?: string;
    }) {
        return RequestV2.get(`${this.uriV2}`, params);
    }

    public static update(id: number, branch: RequestParams) {
        return RequestV2.put(`${this.uriV2}/${id}`, branch);
    }

    public static delete(id: number) {
        return RequestV2.del(`${this.uriV2}/${id}`);
    }
}
