/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import { Form, Button, Spinner, Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import isFriendlyHttpError from '../../../../utils/isFriendlyHttpError';
import PointHttpService from '../../../../services/http/point-http';

interface filters {
    seasonName: string;
    seasonStart: Date;
    seasonFinish: Date;
}

const PointsSeasonModal = (props: any) => {
    const [loading, setLoading] = useState(false);

    const {
        control: formControl,
        watch,
        reset,
        setValue,
    } = useForm<filters>({
        shouldUnregister: false,
        defaultValues: {
            seasonName: '',
            seasonStart: '',
            seasonFinish: '',
        },
    });

    const fieldsData = watch();

    const createNewSeason = async () => {
        setLoading(true);

        const seasons = await PointHttpService.getPointsSeasons();
        let error = false;

        seasons.data.data.forEach((season: any) => {
            const finish = new Date(season.seasonFinish);

            if (
                fieldsData.seasonStart < finish ||
                fieldsData.seasonFinish < fieldsData.seasonStart
            ) {
                error = true;

                setLoading(false);
            }
        });

        if (error) {
            toast.error(
                'Erro ao salvar temporada! Já existe uma temporada vigente nesse período!',
            );
            return;
        }

        try {
            if (!error) {
                const response = await PointHttpService.savePointsSeason(
                    fieldsData,
                );

                if (response) {
                    props.update();
                    toast.success('Temporada salva com sucesso!');
                }

                setLoading(false);
            }
        } catch (err) {
            setLoading(false);

            // eslint-disable-next-line @typescript-eslint/no-shadow
            const error = err as any;

            if (isFriendlyHttpError(error)) {
                toast.error(error.message);
                return;
            }

            toast.error('Erro ao salvar temporada!');
        }
    };

    const editSeason = async () => {
        setLoading(true);

        const seasons = await PointHttpService.getPointsSeasons();
        let error = false;

        seasons.data.data.forEach((season: any) => {
            const finish = new Date(season.seasonFinish);

            if (
                fieldsData.seasonStart < finish ||
                fieldsData.seasonFinish < fieldsData.seasonStart
            ) {
                error = true;

                setLoading(false);
            }
        });

        if (error) {
            toast.error(
                'Erro ao salvar temporada! Já existe uma temporada vigente nesse período!',
            );
            return;
        }

        try {
            if (!error) {
                const response = await PointHttpService.editPointsSeason(
                    props.selectedSeason.id,
                    fieldsData,
                );

                if (response) {
                    props.update();
                    toast.success('Temporada salva com sucesso!');
                }

                setLoading(false);
            }
        } catch (err) {
            setLoading(false);

            // eslint-disable-next-line @typescript-eslint/no-shadow
            const error = err as any;

            if (isFriendlyHttpError(error)) {
                toast.error(error.message);
                return;
            }

            toast.error('Erro ao salvar temporada!');
        }
    };

    useEffect(() => {
        if (props.selectedSeason) {
            setValue('seasonName', props.selectedSeason.name);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedSeason]);

    useEffect(() => {
        if (!props.selectedSeason) {
            reset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]);

    return (
        <>
            <Modal
                className="modal-dialog-scrollable"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                size="lg"
                centered
            >
                <Modal.Header closeButton onHide={props.onHide}>
                    <Modal.Title
                        className="ml-3 mr-3"
                        id="contained-modal-title-vcenter"
                    >
                        {props.isEdit
                            ? 'Editar temporada'
                            : 'Criar nova temporada'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="w-100">
                    <Form.Group controlId="formPointsConfiguration">
                        <Form.Label>Nome da temporada:</Form.Label>

                        <Controller
                            className="form-control"
                            render={({ onChange, value }) => (
                                <Form.Control
                                    className="form-control"
                                    name="seasonName"
                                    type="text"
                                    value={value}
                                    onChange={onChange}
                                />
                            )}
                            rules={{ required: 'Campo obrigatório' }}
                            control={formControl}
                            name="seasonName"
                            defaultValue={null}
                        />
                    </Form.Group>

                    <Form.Group controlId="formPointsConfiguration">
                        <Form.Label>Data de início da temporada:</Form.Label>

                        <Controller
                            className="form-control"
                            render={({ onChange, value }) => (
                                <Form.Control
                                    className="form-control"
                                    value={value}
                                    onChange={onChange}
                                    type="date"
                                />
                            )}
                            rules={{ required: 'Campo obrigatório' }}
                            control={formControl}
                            name="seasonStart"
                            defaultValue={null}
                        />
                    </Form.Group>

                    <Form.Group controlId="formPointsConfiguration">
                        <Form.Label>Data de fim da temporada:</Form.Label>

                        <Controller
                            className="form-control"
                            render={({ onChange, value }) => (
                                <Form.Control
                                    className="form-control"
                                    value={value}
                                    onChange={onChange}
                                    type="date"
                                />
                            )}
                            rules={{ required: 'Campo obrigatório' }}
                            control={formControl}
                            name="seasonFinish"
                            defaultValue={null}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer className="ml-3 mr-3 mb-2">
                    <Button
                        variant="outline-primary"
                        style={{ width: '150px' }}
                        onClick={() => {
                            reset();
                            props.isOpen(false);
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button
                        style={{ width: '150px' }}
                        onClick={props.isEdit ? editSeason : createNewSeason}
                    >
                        {loading ? (
                            <Spinner animation="border" />
                        ) : (
                            'Salvar temporada'
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default PointsSeasonModal;
