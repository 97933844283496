/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import {
    Button,
    Col,
    Form,
    InputGroup,
    Row,
    Spinner,
    Table,
} from 'react-bootstrap';
import Highlighter from 'react-highlight-words';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { toast } from 'react-toastify';
import NotificationIcon from '../../../../constants/notificationIcon';
import { useConfiguration } from '../../../../contexts/configurationContext';
import kitsHttpService from '../../../../services/http/kit-http';
import { StyledPageTitle } from '../../../../styles/pageTitle';
import ConfirmationDialog from '../../../../utils/ConfirmationDialog';
import { StyledTh } from '../styles';
import KitImport from './KitImport/KitImport';
import KitHttpService from '../../../../services/http/kit-v2.http';

const KitTable = () => {
    const rowsPerPage = 20;
    const [term, setTerm] = useState('');
    const [page, setPage] = useState(1);
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [loading, setLoading] = useState(false);

    const { kits, setKits, showKitsImportModal, setShowKitsImportModal } =
        useConfiguration();

    const [showRemoveKitsModal, setShowRemoveKitsModal] =
        useState<boolean>(false);

    const infiniteRef: any = useInfiniteScroll({
        loading,
        hasNextPage,
        // eslint-disable-next-line no-return-assign, no-param-reassign
        onLoadMore: () => setPage((p: any) => (p += 1)),
    });

    async function loadKits() {
        setLoading(true);

        try {
            const response = await KitHttpService.getKits({
                term,
                skip: page - 1,
                take: rowsPerPage,
            });

            const totalItems = response.data.total;

            setKits((prevKits: any) => [...prevKits, ...response.data.data]);
            setHasNextPage(!(+kits.length >= +totalItems));
            // eslint-disable-next-line no-empty
        } catch (error) {}

        setLoading(false);
    }

    useEffect(() => {
        setKits([]);
        setIsFirstRender(false);
    }, []);

    useEffect(() => {
        loadKits();
    }, []);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (!isFirstRender) {
            const timer = setTimeout(() => {
                setKits([]);
                setPage(1);
                loadKits();
            }, 500);

            return () => clearTimeout(timer);
        }
    }, [term]);

    useEffect(() => {
        if (!isFirstRender) {
            loadKits();
        }
    }, [page]);

    const deleteKits = async () => {
        setLoading(true);

        try {
            await kitsHttpService.deleteKits();

            toast.success('Kits excluídos.');

            const response = await KitHttpService.getKits({
                take: rowsPerPage,
            });

            setKits(response.data.kits);
        } catch (error) {
            const err = error as any;

            toast.error(err.message);
        }

        setLoading(false);
    };

    return (
        <>
            <KitImport
                show={showKitsImportModal}
                onHide={() => {
                    setShowKitsImportModal(false);
                    loadKits();
                }}
            />
            <Row>
                <Col>
                    <StyledPageTitle className="mt-2">Kits</StyledPageTitle>
                </Col>
                <Col>
                    <Button
                        className="float-right w-25 m-2"
                        onClick={() => {
                            setShowRemoveKitsModal(true);
                        }}
                    >
                        Limpar Kits
                    </Button>
                    <Button
                        className="float-right w-25 m-2"
                        onClick={() => {
                            setShowKitsImportModal(true);
                        }}
                    >
                        Importar CSV
                    </Button>
                </Col>
            </Row>
            <Row>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            <i className="fas fa-search" />
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                        type="text"
                        value={term}
                        onChange={(event) => setTerm(event.target.value)}
                        placeholder="Digite aqui o que procura..."
                    />
                </InputGroup>
            </Row>
            <Row>
                <Table
                    ref={infiniteRef}
                    bordered
                    hover
                    size="sm"
                    className="text-center"
                >
                    <thead>
                        <tr>
                            <StyledTh>Módulo</StyledTh>
                            <StyledTh>Tipo de Telhado</StyledTh>
                            <StyledTh>ID ERP</StyledTh>
                            <StyledTh>Potencia</StyledTh>
                        </tr>
                    </thead>
                    <tbody>
                        {kits &&
                            kits.map((item: any, index: any) => (
                                <tr key={index}>
                                    <td>
                                        <Highlighter
                                            autoEscape
                                            highlightClassName="highlight-term"
                                            searchWords={[term]}
                                            textToHighlight={item.module.name}
                                        />
                                    </td>
                                    <td>
                                        <Highlighter
                                            autoEscape
                                            highlightClassName="highlight-term"
                                            searchWords={[term]}
                                            textToHighlight={item.roofType.name}
                                        />
                                    </td>
                                    <td>
                                        <Highlighter
                                            autoEscape
                                            highlightClassName="highlight-term"
                                            searchWords={[term]}
                                            textToHighlight={item.componentId}
                                        />
                                    </td>
                                    <td>
                                        <Highlighter
                                            autoEscape
                                            highlightClassName="highlight-term"
                                            searchWords={[term]}
                                            textToHighlight={item.power}
                                        />
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
            </Row>

            <Row className="mx-4">
                <br />
                {loading && hasNextPage && (
                    <Col md={12} className="text-center">
                        <Spinner animation="border" />
                    </Col>
                )}

                {!hasNextPage && (
                    <Col md={12} className="text-center">
                        <strong style={{ color: '#adadad' }}>
                            Sem itens para carregar
                        </strong>
                    </Col>
                )}
            </Row>

            {showRemoveKitsModal && (
                <ConfirmationDialog
                    show={showRemoveKitsModal}
                    onHide={() => setShowRemoveKitsModal(false)}
                    onConfirm={deleteKits}
                    icon={NotificationIcon.Warning}
                    title="Limpar kits"
                    text="Deseja limpar a tabela de kits?"
                />
            )}
        </>
    );
};

export default KitTable;
