import ConfigurationReference from '../../constants/configurationReference';
import RequestV2 from './request-v2';

export default class ConfigurationHttpService {
    private static uriV2 = 'configuration';

    public static readOne(reference: ConfigurationReference) {
        return RequestV2.get(`${this.uriV2}/${reference}`);
    }

    public static update(
        reference: ConfigurationReference,
        configuration: { value: string | null },
    ) {
        return RequestV2.patch(`${this.uriV2}/${reference}`, configuration);
    }
}
