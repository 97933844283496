/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import { Col, Modal, Row, Form, Button, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { StyledRow, StyledTitle } from './styles';
import { ReactComponent as ReactCancelIcon } from '../../../../assets/icons/remove.svg';
import SalesCampaignHttpService from '../../../../services/http/sales-campaign-http';
import { Campaign } from '../SalesCampaignModal';

export interface Product {
    id?: number;
    campaignId: number;
    erpCode: string;
    name: string;
    quantity: number;
}

interface Props {
    show: boolean;
    campaign: Campaign;
    reset(): void;
    onHide(): void;
}

const SalesCampaignProductsModal = (props: Props) => {
    const newProduct: Product = {
        campaignId: props.campaign.id || -1,
        erpCode: '',
        name: '',
        quantity: 1,
    };

    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState<Product[]>([newProduct]);

    // eslint-disable-next-line consistent-return
    async function saveProducts() {
        setLoading(true);

        try {
            const finalProducts = products.filter(
                (product: Product) =>
                    product.erpCode !== '' &&
                    product.name !== '' &&
                    product.quantity !== 0,
            );

            // eslint-disable-next-line no-restricted-syntax
            for (const product of finalProducts) {
                // eslint-disable-next-line no-await-in-loop
                await SalesCampaignHttpService.save(product);
            }

            // eslint-disable-next-line no-restricted-syntax
            for (const product of props.campaign.products) {
                const found = finalProducts.find(
                    (originalProduct: Product) =>
                        originalProduct.id === product.id,
                );

                if (!found) {
                    // eslint-disable-next-line no-await-in-loop
                    await SalesCampaignHttpService.deleteItem(
                        product.id,
                        product.campaignId,
                    );
                }
            }

            props.onHide();
            props.reset();

            return toast.success('Produto(s) salvo(s) com sucesso');
        } catch (error) {
            console.log('error -> ', error);
            toast.error('Erro ao salvar produtos');
        }

        setLoading(false);
    }

    const onChangeText = (value: any, index: number, field: string) => {
        const product = products[index];

        if (field === 'erp_code') {
            product.erpCode = value;
        } else if (field === 'name') {
            product.name = value;
        } else if (field === 'quantity') {
            product.quantity = value;
        }

        products[index] = product;

        setProducts([...products]);
    };

    const onRemove = (removeProduct: Product) => {
        setProducts(
            products.filter((product: Product) => product !== removeProduct),
        );
    };

    const addNewProduct = () => {
        setProducts((previousProducts: Product[]) => [
            newProduct,
            ...previousProducts,
        ]);
    };

    useEffect(() => {
        if (props.campaign.products.length > 0) {
            setProducts(props.campaign.products);
        }
    }, [props]);

    return (
        <>
            <Modal
                dialogClassName="modal-50w"
                show={props.show}
                onHide={props.onHide}
                size="xl"
                centered
            >
                <Modal.Header>
                    <Modal.Title
                        className="ml-3 mr-3"
                        id="contained-modal-title-vcenter"
                    >
                        {props.campaign.products
                            ? 'Editar Produtos'
                            : 'Cadastrar Produtos'}
                    </Modal.Title>
                    <Button
                        className="float-right"
                        size="sm"
                        style={{ height: '2rem' }}
                        onClick={() => addNewProduct()}
                    >
                        +Adicionar Produto
                    </Button>
                </Modal.Header>
                <Modal.Body className="w-100 mb-4">
                    <StyledRow className="ml-0 mt-4 mr-1">
                        <Col>
                            <StyledTitle>Código</StyledTitle>
                        </Col>
                        <Col>
                            <StyledTitle>Nome</StyledTitle>
                        </Col>
                        <Col>
                            <StyledTitle>Quantidade</StyledTitle>
                        </Col>
                        <Col sm={1} />
                    </StyledRow>
                    {products &&
                        products.map((product: Product, index: number) => (
                            <Row className="ml-0 mt-2 mr-1" key={index}>
                                <Col>
                                    <Form.Control
                                        name="erp_code"
                                        value={product.erpCode}
                                        onChange={({ target }) => {
                                            onChangeText(
                                                target.value,
                                                index,
                                                'erp_code',
                                            );
                                        }}
                                        type="string"
                                        className="form-control-nexen"
                                        placeholder="Digite aqui..."
                                    />
                                </Col>
                                <Col>
                                    <Form.Control
                                        name="name"
                                        value={product.name}
                                        onChange={({ target }) => {
                                            onChangeText(
                                                target.value,
                                                index,
                                                'name',
                                            );
                                        }}
                                        type="string"
                                        className="form-control-nexen"
                                        placeholder="Digite aqui..."
                                    />
                                </Col>
                                <Col>
                                    <Form.Control
                                        name="quantity"
                                        value={product.quantity}
                                        onChange={({ target }) => {
                                            onChangeText(
                                                target.value,
                                                index,
                                                'quantity',
                                            );
                                        }}
                                        min={1}
                                        type="number"
                                        className="form-control-nexen"
                                        placeholder="Digite aqui..."
                                    />
                                </Col>

                                <Col sm={1}>
                                    <Button
                                        variant="text"
                                        onClick={() => onRemove(product)}
                                    >
                                        <ReactCancelIcon
                                            fill="#BDBDBD"
                                            width="30"
                                            height="30"
                                        />
                                    </Button>
                                </Col>
                            </Row>
                        ))}

                    <StyledRow className="ml-0 mt-5 mr-1">
                        <Col sm={6} />
                        <Col>
                            <Button
                                className="w-100 float-right"
                                variant="outline-primary"
                                onClick={() => {
                                    props.onHide();
                                }}
                            >
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                className="w-100 float-right"
                                onClick={() => saveProducts()}
                            >
                                {loading ? (
                                    <Spinner animation="border" size="sm" />
                                ) : (
                                    'Salvar'
                                )}
                            </Button>
                        </Col>
                    </StyledRow>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SalesCampaignProductsModal;
