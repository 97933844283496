import { BannerLocation } from '../../constants/configuration';
import RequestV2 from './request-v2';

export default class BannerHttpService {
    private static uriV2 = 'banner';

    public static create(banner: globalThis.FormData) {
        return RequestV2.post(`${this.uriV2}`, banner);
    }

    public static readMany(params: {
        skip?: number;
        take?: number;
        term?: string;
        active?: boolean;
        location?: BannerLocation;
    }) {
        return RequestV2.get(`${this.uriV2}`, params);
    }

    public static update(
        id: number,
        banner: {
            fileName: string;
            location: BannerLocation;
            referLink: string;
            active: boolean;
        },
    ) {
        return RequestV2.put(`${this.uriV2}/${id}`, banner);
    }

    public static delete(id: number) {
        return RequestV2.del(`${this.uriV2}/${id}`);
    }
}
