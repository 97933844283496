import RequestV2 from './request-v2';

export default class SalesCampaignHttpService {
    private static uriV2 = 'black-friday';

    public static async save(body: any) {
        if ('id' in body && !!body.id) {
            return RequestV2.put(
                `${this.uriV2}/season/${body.campaignId}/item/${body.id}`,
                body,
            );
        }
        return RequestV2.post(
            `${this.uriV2}/season/${body.campaignId}/item`,
            body,
        );
    }

    public static async deleteItem(id: any, seasonId: number) {
        return RequestV2.del(`${this.uriV2}/season/${seasonId}/item/${id}`);
    }

    public static async indexSeason(params: {}) {
        return RequestV2.get(`${this.uriV2}/season`, params);
    }

    public static async saveSeason(body: any) {
        if ('id' in body && !!body.id) {
            return RequestV2.put(`${this.uriV2}/season/${body.id}`, body);
        }
        return RequestV2.post(`${this.uriV2}/season`, body);
    }
}
