import React, { useState, useRef, useEffect } from 'react';
import {
    Row,
    Col,
    Form,
    InputGroup,
    Button,
    Spinner,
    Table,
    Dropdown,
} from 'react-bootstrap';
import Highlighter from 'react-highlight-words';
import { toast } from 'react-toastify';
import { useInfiniteQuery } from 'react-query';
import { StyledPageTitle } from '../../../styles/pageTitle';
import { StyledTh } from './styles';
import { ReactComponent as ReactMoreIcon } from '../../../assets/icons/more.svg';
import AddReturnUsersModal from './AddReturnUsersModal';
import ReturnUsersHttpService from '../../../services/http/return-users-http';
import { rowsPerPage } from '../../../constants/pagination';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';

const ReturnUsers = () => {
    const [term, setTerm] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [addUserReturnModal, setAddUserReturnModal] = useState(false);

    const loadMoreRef = useRef();

    // eslint-disable-next-line consistent-return
    async function getReturnUsers({ pageParam = 1 }: any) {
        setLoading(true);

        const params = {
            term,
            skip: pageParam,
            take: rowsPerPage,
        };

        try {
            const { data } = await ReturnUsersHttpService.index(params);

            return {
                data: data.data,
                currentPage: pageParam,
                pages: Math.ceil(data.total / 5),
            };
        } catch (error) {
            toast.error('Houve um erro ao buscar os usuários devolução');
        } finally {
            setLoading(false);
        }
    }

    const { data, fetchNextPage, hasNextPage, refetch, isFetchingNextPage } =
        useInfiniteQuery('returnUsers', getReturnUsers, {
            // eslint-disable-next-line consistent-return
            getNextPageParam: (response: any) => {
                if (response.currentPage + 1 < response.pages) {
                    return response.currentPage + 1;
                }
            },
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        });

    useIntersectionObserver({
        target: loadMoreRef,
        onIntersect: fetchNextPage,
        enabled: hasNextPage,
    });

    const deleteReturnUser = async (returnUser: any) => {
        setLoading(true);

        try {
            const response = await ReturnUsersHttpService.delete(returnUser.id);

            if (response.status === 200) {
                refetch();
                toast.success('Usuário deletado com sucesso!');
            }
        } catch (error) {
            toast.error('Houve um erro ao deletar o usuário devolução');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            refetch();
        }, 500);

        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [term]);

    return (
        <>
            <AddReturnUsersModal
                refetch={refetch}
                show={addUserReturnModal}
                isOpen={setAddUserReturnModal}
                onHide={() => {
                    setAddUserReturnModal(false);
                }}
            />

            <Row>
                <Col>
                    <StyledPageTitle className="mt-2">
                        Usuários Devolução
                    </StyledPageTitle>
                </Col>
                <Col>
                    <Button
                        className="float-right w-25 m-2"
                        onClick={() => {
                            setAddUserReturnModal(true);
                        }}
                    >
                        Incluir usuário
                    </Button>
                </Col>
            </Row>
            <Row>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            <i className="fas fa-search" />
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                        type="text"
                        value={term}
                        onChange={(event) => setTerm(event.target.value)}
                        placeholder="Digite aqui o que procura..."
                    />
                </InputGroup>
            </Row>
            <Row>
                <Table bordered hover size="sm" className="text-center">
                    <thead>
                        <tr>
                            <StyledTh>ID</StyledTh>
                            <StyledTh>Nome</StyledTh>
                            <StyledTh>E-mail</StyledTh>
                            <StyledTh />
                        </tr>
                    </thead>
                    <tbody>
                        {data?.pages &&
                            data?.pages.map(
                                (group: any) =>
                                    group?.data &&
                                    group?.data.map(
                                        (user: any, index: number) => (
                                            <tr key={index}>
                                                <td style={{ padding: 10 }}>
                                                    {user.id}
                                                </td>
                                                <td style={{ padding: 10 }}>
                                                    <Highlighter
                                                        autoEscape
                                                        highlightClassName="highlight-term"
                                                        searchWords={[term]}
                                                        textToHighlight={
                                                            user.user.name
                                                        }
                                                    />
                                                </td>
                                                <td style={{ padding: 10 }}>
                                                    <Highlighter
                                                        autoEscape
                                                        highlightClassName="highlight-term"
                                                        searchWords={[term]}
                                                        textToHighlight={
                                                            user.user.email
                                                        }
                                                    />
                                                </td>
                                                <td>
                                                    <Dropdown key="left">
                                                        <Dropdown.Toggle
                                                            bsPrefix="nexen"
                                                            className="mt-n3 mb-n3"
                                                            as={Button}
                                                            variant="text"
                                                        >
                                                            <ReactMoreIcon
                                                                fill="#bdbdbd"
                                                                width="10"
                                                                height="20"
                                                            />
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <Dropdown.Item
                                                                onClick={() => {
                                                                    deleteReturnUser(
                                                                        user,
                                                                    );
                                                                }}
                                                            >
                                                                Remover
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        ),
                                    ),
                            )}
                    </tbody>
                </Table>
                <Col md={12} className="text-center" ref={loadMoreRef as any}>
                    {(isLoading || isFetchingNextPage) && (
                        <Col md={12} className="text-center">
                            <Spinner animation="border" />
                        </Col>
                    )}
                    {data?.pages[0]?.data.length === 0 && (
                        <Col md={12} className="text-center">
                            <strong style={{ color: '#adadad' }}>
                                Sem itens para carregar
                            </strong>
                        </Col>
                    )}
                </Col>
            </Row>
        </>
    );
};

export default ReturnUsers;
