import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Accessory from '../../../../../shared/interfaces/accessory.interface';
import {
    optionsTheme,
    theme,
    tradeControl,
} from '../../../../../styles/react-select-config';
import getValidationsErrors from '../../../../../utils/getValidationsErrors';
import accessoriesRules from '../../../../../validations/accessories';
import { SelectOption } from '../../../../Select';
import { StyledRow, StyledSelect, StyledTitle } from './styles';
import ProductHttpService from '../../../../../services/http/product.http';

interface AccessoryData {
    productErpCode: SelectOption<string> | '';
    accessoryErpCode: SelectOption<string> | '';
    formula: string;
}

interface Props {
    show: boolean;
    selectedAccessory: Accessory;
    onHide: () => void;
    products: Array<SelectOption<string>>;
    disableProduct: boolean;
    errors: {
        accessoryErpCode: string;
        productErpCode: string;
        formula: string;
    };
    setErrors: React.Dispatch<
        React.SetStateAction<{
            accessoryErpCode: string;
            productErpCode: string;
            formula: string;
        }>
    >;
}

const AccessoryEditor = ({
    show,
    onHide,
    products,
    disableProduct,
    selectedAccessory,
    errors,
    setErrors,
}: Props) => {
    const [loading, setLoading] = useState(false);
    const { watch, control, setValue, reset } = useForm<AccessoryData>({
        defaultValues: {
            productErpCode: '',
            accessoryErpCode: '',
            formula: '',
        },
    });

    const formData = watch();

    async function saveAccessory() {
        setLoading(true);

        setErrors({
            accessoryErpCode: '',
            productErpCode: '',
            formula: '',
        });

        if (
            formData.productErpCode &&
            formData.accessoryErpCode &&
            formData.productErpCode === formData.accessoryErpCode
        ) {
            toast.error('Produto e acessório não podem ser iguais');
            setLoading(false);
            return;
        }

        try {
            const schemaAccessory = Yup.object().shape(accessoriesRules);

            await schemaAccessory.validate(formData, {
                abortEarly: false,
                context: formData,
            });
        } catch (err) {
            const er = err as any;

            setErrors(getValidationsErrors(er) as any);
            setLoading(false);

            return;
        }

        try {
            const accessory: Accessory = {
                id: selectedAccessory?.id,
                productErpCode: formData.productErpCode
                    ? formData.productErpCode.value
                    : selectedAccessory?.productErpCode,
                productName: formData.productErpCode
                    ? formData.productErpCode.label
                          .split(' - ')
                          .slice(1)
                          .join(' - ')
                    : selectedAccessory?.productName,
                accessoryErpCode: formData.accessoryErpCode
                    ? formData.accessoryErpCode.value
                    : selectedAccessory?.accessoryErpCode,
                accessoryName: formData.accessoryErpCode
                    ? formData.accessoryErpCode.label
                          .split(' - ')
                          .slice(1)
                          .join(' - ')
                    : selectedAccessory?.accessoryName,
                formula: formData.formula
                    ? formData.formula
                    : selectedAccessory?.formula,
            };

            await ProductHttpService.saveAccessory(accessory);

            toast.success('Acessório salvo com sucesso');

            setLoading(false);
            onHide();
        } catch (err) {
            if (typeof err === 'object' && err !== null && 'message' in err) {
                toast.error(err.message);
            } else {
                toast.error('Erro ao salvar acessório');
            }
            setLoading(false);
        }
    }

    const handleFormulaChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        let newValue = event.target.value;

        newValue = newValue.toUpperCase().replace(/[^PMI+\-*/0-9]/g, '');

        setValue('formula', newValue);
    };

    useEffect(() => {
        if (selectedAccessory.id) {
            reset({
                productErpCode: products.find(
                    (product) =>
                        product.value === selectedAccessory.productErpCode,
                ),
                accessoryErpCode: products.find(
                    (product) =>
                        product.value === selectedAccessory.accessoryErpCode,
                ),
                formula: selectedAccessory.formula,
            });
        }
    }, [products, reset, selectedAccessory]);

    return (
        <>
            <Modal show={show} onHide={onHide} size="lg" centered>
                <Modal.Header>
                    <Modal.Title
                        className="ml-3 mr-3"
                        id="contained-modal-title-vcenter"
                    >
                        Acessório
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="w-100">
                    <StyledRow className="ml-0 mt-2 mr-1">
                        <Col>
                            <StyledTitle>Produto</StyledTitle>
                        </Col>
                    </StyledRow>
                    <Row className="ml-0 mt-2 mr-1">
                        <Col>
                            <Form.Group controlId="formBasicProduct">
                                <Controller
                                    control={control}
                                    name="productErpCode"
                                    render={({ onChange, value }) => (
                                        <>
                                            <Form.Control
                                                style={{ display: 'none' }}
                                                isInvalid={
                                                    !formData.productErpCode
                                                }
                                            />
                                            <StyledSelect>
                                                <Select
                                                    placeholder="Selecione..."
                                                    className={
                                                        errors.productErpCode
                                                            ? 'invalid-select'
                                                            : ''
                                                    }
                                                    options={products}
                                                    isDisabled={disableProduct}
                                                    value={value}
                                                    onChange={onChange}
                                                    styles={{
                                                        control: tradeControl,
                                                        option: optionsTheme,
                                                    }}
                                                    theme={theme}
                                                    isInvalid={
                                                        !!errors.productErpCode
                                                    }
                                                />
                                            </StyledSelect>
                                            {errors.productErpCode && (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.productErpCode}
                                                </Form.Control.Feedback>
                                            )}
                                        </>
                                    )}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <StyledRow className="ml-0 mt-2 mr-1">
                        <Col>
                            <StyledTitle>Acessório</StyledTitle>
                        </Col>
                    </StyledRow>
                    <Row className="ml-0 mt-2 mr-1">
                        <Col>
                            <Form.Group>
                                <Controller
                                    className="form-control-nexen"
                                    control={control}
                                    name="accessoryErpCode"
                                    render={({ onChange, value }) => (
                                        <>
                                            <Form.Control
                                                style={{ display: 'none' }}
                                                isInvalid={
                                                    !formData.accessoryErpCode
                                                }
                                            />
                                            <StyledSelect>
                                                <Select
                                                    options={products}
                                                    className={
                                                        errors.accessoryErpCode ||
                                                        (formData.accessoryErpCode &&
                                                            formData.accessoryErpCode ===
                                                                formData.productErpCode)
                                                            ? 'invalid-select'
                                                            : ''
                                                    }
                                                    isDisabled={disableProduct}
                                                    onChange={onChange}
                                                    value={value}
                                                    styles={{
                                                        control: tradeControl,
                                                        option: optionsTheme,
                                                    }}
                                                    theme={theme}
                                                    placeholder="Selecione..."
                                                    isInvalid={
                                                        !!errors.accessoryErpCode
                                                    }
                                                />
                                            </StyledSelect>
                                            {errors.accessoryErpCode && (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.accessoryErpCode}
                                                </Form.Control.Feedback>
                                            )}
                                        </>
                                    )}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <StyledRow className="ml-0 mt-2 mr-1">
                        <Col>
                            <StyledTitle>Fórmula</StyledTitle>
                        </Col>
                    </StyledRow>
                    <Row className="ml-0 mt-2 mr-1">
                        <Col>
                            <Controller
                                className="form-control-nexen"
                                control={control}
                                name="formula"
                                render={({ value }) => (
                                    <>
                                        <Form.Control
                                            type="text"
                                            placeholder="Digite aqui..."
                                            value={value}
                                            onChange={handleFormulaChange}
                                            isInvalid={!!errors.formula}
                                        />
                                    </>
                                )}
                            />
                            {errors?.formula && (
                                <Form.Control.Feedback type="invalid">
                                    {errors?.formula}
                                </Form.Control.Feedback>
                            )}
                        </Col>
                    </Row>

                    <StyledRow className="ml-0 mt-5 mr-1 mb-4">
                        <Col sm={6} />
                        <Col>
                            <Button
                                className="w-100 float-right"
                                variant="outline-primary"
                                onClick={() => {
                                    onHide();
                                    setErrors({
                                        accessoryErpCode: '',
                                        productErpCode: '',
                                        formula: '',
                                    });
                                }}
                            >
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                className="w-100 float-right"
                                onClick={() => {
                                    saveAccessory();
                                }}
                                disabled={disableProduct}
                            >
                                Salvar
                                {loading && (
                                    <Spinner animation="border" size="sm" />
                                )}
                            </Button>
                        </Col>
                    </StyledRow>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AccessoryEditor;
