import Request from './request';

export interface ListInvertersParams {
    skip?: number;
    take?: number;
    term?: string;
    brandFilter?: string;
    active?: string;
}

export default class kitsHttpService {
    private static uri = 'kits';

    public static async saveKits(item: FormData) {
        return Request.post(`${this.uri}/kits/upload`, item, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    }

    public static async deleteKits() {
        return Request.del(`${this.uri}/kits`);
    }

    public static async saveInverterBrand(inverterBrand: any) {
        return Request.post(`${this.uri}/inverterBrands`, inverterBrand);
    }

    public static async deleteInverterBrand(id: number) {
        return Request.del(`${this.uri}/inverterBrands/${id}`);
    }
}
