import { Form, Card, Col, Accordion, Button } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { ChangeEvent, useEffect } from 'react';
import PaymentHttpService from '../../../services/http/payment-http';
import PaymentMethod from '../../../shared/interfaces/paymentMethod.interface';
import StyledCard from './styles';
import DiscountsListAccordion from './DiscountsListAccordion';
import { Discount } from '../../../shared/interfaces/discount.interface';

interface DiscountsPaymentMethodsListProps {
    setDiscounts: (discounts: Record<string, Discount>) => void;
    setTotalInstallments: (lenght: number) => void;
    discounts: Record<string, Discount>;
}

export default function DiscountsList({
    setDiscounts,
    setTotalInstallments,
    discounts,
}: DiscountsPaymentMethodsListProps) {
    const queryConfigs = {
        enabled: true,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        retry: false,
    };

    const { data: paymentMethods } = useQuery<PaymentMethod[]>({
        queryKey: ['paymentMethods'],
        queryFn: async () => {
            const response = await PaymentHttpService.listPaymentMethods();
            return response.data.data;
        },
        ...queryConfigs,
        refetchOnMount: 'always',
    });

    const orderPaymentMethods = (a: PaymentMethod, b: PaymentMethod) => {
        return a.name.localeCompare(b.name);
    };

    useEffect(() => {
        const calculateTotalInstallments = (methods: PaymentMethod[]) => {
            const totalInstallments = methods.reduce(
                (total, method) => total + method.maxInstallments,
                0,
            );

            setTotalInstallments(totalInstallments);
        };

        if (paymentMethods) {
            calculateTotalInstallments(paymentMethods);
        }
    }, [paymentMethods, setTotalInstallments]);

    const handleOnChange = (paymentKey: string, discount: Discount) => {
        const newDiscounts = { ...discounts };

        newDiscounts[paymentKey] = discount;

        setDiscounts(newDiscounts);
    };

    const handleChangeAll = async (e: ChangeEvent<HTMLInputElement>) => {
        const newPercentage = Math.max(
            0,
            Math.min(100, parseFloat(e.target.value)),
        );

        e.target.value = newPercentage.toString();

        const updatedDiscounts: Record<string, Discount> = {};

        const discountPromises = paymentMethods?.map(async (payment) => {
            for (
                let installment = 1;
                installment <= payment.maxInstallments;
                installment += 1
            ) {
                const paymentKey = `${payment.reference}-${installment}`;

                updatedDiscounts[paymentKey] = {
                    value: newPercentage,
                    method: payment.reference,
                    installment,
                };
            }
        });

        if (discountPromises) {
            await Promise.all(discountPromises);

            setDiscounts(updatedDiscounts);
        }
    };

    return (
        <Col>
            <StyledCard className="mt-4 w-100">
                <Card.Header as="h5">Política de descontos</Card.Header>

                <Card.Body className="light-scrollbar">
                    <Accordion>
                        <div key="all" className="mb-2">
                            <Accordion.Toggle
                                as={Button}
                                variant="link"
                                eventKey="all"
                                style={{
                                    display: 'contents',
                                    fontSize: '14px',
                                }}
                            >
                                Todas as formas de pagamento
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="all">
                                <Form.Control
                                    type="number"
                                    onChange={handleChangeAll}
                                    placeholder="Informe a % de desconto máximo..."
                                    className="mb-4 mt-1"
                                />
                            </Accordion.Collapse>
                        </div>
                        {paymentMethods
                            ?.sort(orderPaymentMethods)
                            .map((item) => (
                                <DiscountsListAccordion
                                    key={item.name}
                                    paymentMethod={item}
                                    discounts={discounts}
                                    onChange={handleOnChange}
                                />
                            ))}
                    </Accordion>
                </Card.Body>
            </StyledCard>
        </Col>
    );
}
