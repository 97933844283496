/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Col, Modal, Row, Button, Spinner } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import { StyledRow } from './styles';
import { useConfiguration } from '../../../../../contexts/configurationContext';
import kitsHttpService from '../../../../../services/http/kit-http';
import KitHttpService from '../../../../../services/http/kit-v2.http';

const KitImport = (props: any) => {
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [formData, setFormData]: any = useState([]);
    const [currentFile, setCurrentFile] = useState(0);
    const [totalFiles, setTotalFiles] = useState(0);

    const { setShowKitsImportModal, setKits } = useConfiguration();

    async function saveKits() {
        setLoading(true);

        try {
            for (let index = 0; index < formData.length; index += 1) {
                // eslint-disable-next-line no-await-in-loop
                await kitsHttpService.saveKits(formData[index]);
                setCurrentFile(index + 1);
            }

            toast.success('Kits importados com sucesso');

            const response = await KitHttpService.getKits({
                take: 20,
            });

            setCurrentFile(0);
            setTotalFiles(0);
            setKits(response.data.kits);

            setShowKitsImportModal(false);
        } catch (error) {
            const err = error as any;

            let { message } = err;

            if (message.includes('timeout')) {
                message = 'O servidor se encontra ocupado, tente novamente.';
            }

            toast.error(message);
            setCurrentFile(0);
        }

        setLoading(false);
    }

    return (
        <>
            <Modal
                className="modal-dialog-scrollable"
                {...props}
                size="lg"
                centered
            >
                <Modal.Header>
                    <Modal.Title
                        className="ml-3 mr-3"
                        id="contained-modal-title-vcenter"
                    >
                        Importar Kits via CSV
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="w-100">
                    <Row className="mb-3">
                        <Dropzone
                            accept={['.csv']}
                            onDropRejected={() => {
                                toast.error(
                                    'Arquivo muito grande ou formato incorreto.',
                                );
                            }}
                            multiple
                            maxSize={2000000}
                            onDrop={(files: any[]) => {
                                // eslint-disable-next-line @typescript-eslint/no-shadow
                                const formData: any[] = [];

                                files.forEach((file) => {
                                    const data = new FormData();

                                    data.append('file', file, file.name);

                                    formData.push(data);
                                });

                                setTotalFiles(formData.length);
                                setFormData(formData);
                                setDisabled(false);
                            }}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <section className="custom-file-upload">
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <Row className="dropzone-title">
                                            <Col
                                                className="dropzone-title"
                                                sm={12}
                                            >
                                                <h5
                                                    style={{
                                                        color: '#F2994A',
                                                        fontSize: '1rem',
                                                    }}
                                                    className="dropzone-title p-4"
                                                >
                                                    Clique aqui para selecionar
                                                    arquivos ou arraste-os para
                                                    cá. Lembre-se de limpar a
                                                    tabela para evitar registros
                                                    duplicados.
                                                </h5>
                                            </Col>
                                        </Row>
                                        <Row className="mt-n4 ml-4" />
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </Row>

                    <StyledRow className="ml-0 mt-5 mr-1">
                        <Col className="ml-4" sm={6}>
                            <h5>
                                {currentFile} de {totalFiles} processados
                            </h5>
                        </Col>
                        <Col>
                            <Button
                                className="w-100 float-right"
                                variant="outline-primary"
                                onClick={() => {
                                    setShowKitsImportModal(false);
                                }}
                            >
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                disabled={disabled}
                                className="w-100 float-right"
                                onClick={() => {
                                    saveKits();
                                }}
                            >
                                Importar{'   '}
                                {loading && (
                                    <Spinner animation="border" size="sm" />
                                )}
                            </Button>
                        </Col>
                    </StyledRow>
                </Modal.Body>
                <Modal.Footer className="ml-3 mr-3 mb-2" />
            </Modal>
        </>
    );
};

export default KitImport;
