import RequestV2 from './request-v2';

export default class LibraryHttpService {
    private static uriV2 = 'library';

    public static getLibraries(data: any) {
        return RequestV2.get(`${this.uriV2}`, data);
    }

    public static getLibrary(id: number, data?: any) {
        return RequestV2.get(`${this.uriV2}/${id}`, data);
    }

    public static storeLibrary(data: {}) {
        return RequestV2.post(`${this.uriV2}`, data);
    }

    public static updateLibrary(id: number, data: {}) {
        return RequestV2.put(`${this.uriV2}/${id}`, data);
    }

    public static deleteLibrary(id: number) {
        return RequestV2.del(`${this.uriV2}/${id}`);
    }

    public static getModules(data: any) {
        return RequestV2.get(`${this.uriV2}/module`, data);
    }

    public static getModule(id: number, data?: any) {
        return RequestV2.get(`${this.uriV2}/module/${id}`, data);
    }

    public static storeModule(data: {}) {
        return RequestV2.post(`${this.uriV2}/module`, data);
    }

    public static updateModule(id: number, data: {}) {
        return RequestV2.put(`${this.uriV2}/module/${id}`, data);
    }

    public static deleteModule(id: number) {
        return RequestV2.del(`${this.uriV2}/module/${id}`);
    }

    public static getBrands(data: any) {
        return RequestV2.get(`${this.uriV2}/brand`, data);
    }

    public static getBrand(id: number, data?: any) {
        return RequestV2.get(`${this.uriV2}/brand/${id}`, data);
    }

    public static storeBrand(data: {}) {
        return RequestV2.post(`${this.uriV2}/brand`, data);
    }

    public static updateBrand(id: number, data: {}) {
        return RequestV2.put(`${this.uriV2}/brand/${id}`, data);
    }

    public static deleteBrand(id: number) {
        return RequestV2.del(`${this.uriV2}/brand/${id}`);
    }

    public static getBrandsCategories(data: any) {
        return RequestV2.get(`${this.uriV2}/brand/category`, data);
    }

    public static getBrandCategory(id: number, data?: any) {
        return RequestV2.get(`${this.uriV2}/brand/category/${id}`, data);
    }

    public static storeBrandCategory(data: {}) {
        return RequestV2.post(`${this.uriV2}/brand/category`, data);
    }

    public static updateBrandCategory(id: number, data: {}) {
        return RequestV2.put(`${this.uriV2}/brand/category/${id}`, data);
    }

    public static deleteBrandCategory(id: number) {
        return RequestV2.del(`${this.uriV2}/brand/category/${id}`);
    }

    public static getItems(data: any) {
        return RequestV2.get(`${this.uriV2}/item`, data);
    }

    public static getItem(id: number, data?: any) {
        return RequestV2.get(`${this.uriV2}/item/${id}`, data);
    }

    public static storeItem(data: {}) {
        return RequestV2.post(`${this.uriV2}/item`, data);
    }

    public static updateItem(id: number, data: {}) {
        return RequestV2.put(`${this.uriV2}/item/${id}`, data);
    }

    public static deleteItem(id: number) {
        return RequestV2.del(`${this.uriV2}/item/${id}`);
    }

    public static storeFiles(file: {}) {
        return RequestV2.post(`${this.uriV2}/files`, file);
    }
}
