/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-destructuring */
import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import MaskedInput from 'react-text-mask';
import customerInputsLength from '../../../constants/customerInputsLength';
import { theme, control } from '../../../styles/react-select-config';
import { cepMask } from '../../../utils/masks';
import normalizeString from '../../../utils/strings';
import BrasilService from '../../../services/http/brasil.http';

const CustomerAddress = (props: any) => {
    const [states, setStates] = useState<
        Array<{ label: string; value: string }>
    >([]);
    const [cities, setCities] = useState<
        Array<{ label: string; value: string }>
    >([]);
    const [isDisabled, setDisabled] = useState<boolean>(false);

    const loadCities = async (state: string) => {
        const response = await BrasilService.getCitiesByState(state);

        // eslint-disable-next-line @typescript-eslint/no-shadow
        const cities: any[] = response.map((obj) => {
            return {
                label: obj.nome,
                value: obj.nome,
            };
        });

        cities.unshift({ name: 'Selecione uma cidade', value: '' });

        setCities(cities);
    };

    const getCitiesByState = async (state: string) => {
        const response = await BrasilService.getCitiesByState(state);

        // eslint-disable-next-line @typescript-eslint/no-shadow
        const cities: any[] = response.map((obj) => {
            return {
                label: obj.nome,
                value: obj.nome,
            };
        });

        cities.unshift({ name: 'Selecione uma cidade', value: '' });

        return cities;
    };

    const handleCepChanges = async (event: any) => {
        const value: any = event.target.value;
        const changes = [{ target: { name: 'addressCep', value } }];

        if (value.replace(/\D/g, '').length === 8) {
            try {
                const data = await BrasilService.getDataByCep(
                    value.replace(/\D/g, ''),
                );

                const street = data.street;
                const neighborhood = data.neighborhood;

                const state: any = states.find(
                    (item: any) => item.value === data?.state,
                );

                // eslint-disable-next-line @typescript-eslint/no-shadow
                const cities = await getCitiesByState(state.value);

                const city: any = cities.find(
                    (item: any) =>
                        item.label ===
                        normalizeString(data?.city).toUpperCase(),
                );

                changes.push({ target: { name: 'erpState', value: state } });
                changes.push({ target: { name: 'erpCity', value: city } });
                changes.push({
                    target: { name: 'erpCityName', value: city.label },
                });
                changes.push({
                    target: {
                        name: 'addressDescription',
                        value: street.substring(
                            0,
                            customerInputsLength.addressDescription,
                        ),
                    },
                });
                changes.push({
                    target: {
                        name: 'addressNeighborhood',
                        value: neighborhood.substring(
                            0,
                            customerInputsLength.addressNeighborhood,
                        ),
                    },
                });

                setDisabled(true);
            } catch (err) {
                changes.push({ target: { name: 'erpState', value: {} } });
                changes.push({ target: { name: 'erpCity', value: {} } });
                changes.push({ target: { name: 'erpCityName', value: '' } });
                changes.push({
                    target: { name: 'addressDescription', value: '' },
                });
                changes.push({
                    target: { name: 'addressNeighborhood', value: '' },
                });

                setDisabled(false);
            }
        }

        props.handleChanges(changes);
    };

    useEffect(() => {
        const loadErpStates = async () => {
            const results = await BrasilService.getStates();

            // eslint-disable-next-line array-callback-return
            setStates(
                results.map((obj) => {
                    return {
                        label: obj.nome,
                        value: obj.sigla,
                    };
                }),
            );

            console.log(props.customer);

            if (props.customer.erpState) {
                console.log(results);
                const uf: any = results.find((item: any) => {
                    return item.sigla === props.customer.erpState.value;
                });
                // eslint-disable-next-line @typescript-eslint/no-shadow
                const cities = await getCitiesByState(
                    props.customer.erpState.value,
                );
                const city: any = cities.find(
                    (item: any) =>
                        normalizeString(item.label).toUpperCase() ===
                        normalizeString(
                            props?.customer?.erpCityName,
                        ).toUpperCase(),
                );

                console.log(cities);

                props.handleChanges([
                    {
                        target: {
                            name: 'erpState',
                            value: {
                                name: uf?.nome,
                                code: uf?.sigla,
                                label: uf?.nome,
                                value: uf?.sigla,
                            },
                        },
                    },
                    {
                        target: {
                            name: 'erpCity',
                            value:
                                props.customer.erpCity &&
                                props.customer.erpCityName
                                    ? {
                                          label: props.customer.erpCityName,
                                          value: props.customer.erpCityName,
                                      }
                                    : {
                                          label: city?.label,
                                          value: city?.value,
                                      },
                        },
                    },
                    { target: { name: 'erpCityName', value: city?.label } },
                ]);
            }
        };

        loadErpStates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!props.customer.erpState) {
            return;
        }

        loadCities(props.customer.erpState.value);
    }, [props.customer.erpState]);

    const handleStateChanges = async (value: any) => {
        props.handleChanges([
            {
                target: {
                    name: 'erpState',
                    value,
                },
            },
            { target: { name: 'erpCity', value: null } },
            { target: { name: 'erpCityName', value: null } },
        ]);
        loadCities(value.value ?? value.code ?? value);
    };

    const handleCityChanges = async (value: any) => {
        props.handleChanges([
            { target: { name: 'erpCity', value } },
            { target: { name: 'erpCityName', value: value.label } },
        ]);
    };

    return (
        <>
            <Form.Group controlId="formBasicCep">
                <Form.Label>CEP</Form.Label>
                <Form.Control
                    value={props.customer.addressCep}
                    isInvalid={!!props.errors.addressCep}
                    as={MaskedInput}
                    mask={cepMask}
                    onChange={handleCepChanges}
                    name="addressCep"
                    type="text"
                />
                {props.errors.addressCep && (
                    <Form.Control.Feedback type="invalid">
                        {props.errors.addressCep}
                    </Form.Control.Feedback>
                )}
            </Form.Group>

            <Row>
                <Col lg={6} md={12}>
                    <Form.Group controlId="formBasicCep">
                        <Form.Label>Endereço</Form.Label>
                        <Form.Control
                            value={props.customer.addressDescription}
                            isInvalid={!!props.errors.addressDescription}
                            onChange={props.handleChanges}
                            name="addressDescription"
                            type="text"
                            maxLength={customerInputsLength.addressDescription}
                        />
                        {props.errors.addressDescription && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.addressDescription}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Col>

                <Col lg={6} md={12}>
                    <Form.Group controlId="formBasicCep">
                        <Form.Label>Número</Form.Label>
                        <Form.Control
                            value={props.customer.addressNumber}
                            isInvalid={!!props.errors.addressNumber}
                            onChange={props.handleChanges}
                            name="addressNumber"
                            type="text"
                            maxLength={5}
                        />
                        {props.errors.addressNumber && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.addressNumber}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col lg={6} md={12}>
                    <Form.Group controlId="formBasicState">
                        <Form.Label>Estado</Form.Label>
                        <Select
                            placeholder="Selecione..."
                            noOptionsMessage={() => 'Sem opções'}
                            name="erpState"
                            onChange={handleStateChanges}
                            value={props.customer.erpState}
                            options={states}
                            isMulti={false}
                            styles={{ control }}
                            theme={theme}
                            isDisabled={isDisabled}
                        />
                        {props.errors.erpState && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.erpState}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Col>
                <Col lg={6} md={12}>
                    <Form.Group controlId="formBasicCity">
                        <Form.Label>Cidade</Form.Label>
                        <Form.Control
                            style={{ display: 'none' }}
                            isInvalid={!!props.errors.erpCity}
                        />
                        <Select
                            placeholder="Selecione..."
                            noOptionsMessage={() => 'Sem opções'}
                            name="erpCity"
                            onChange={handleCityChanges}
                            value={props.customer.erpCity}
                            options={cities}
                            isMulti={false}
                            styles={{ control }}
                            theme={theme}
                            isDisabled={isDisabled}
                        />
                        {props.errors.erpCity && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.erpCity}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Col>

                <Col>
                    <Form.Group controlId="formBasicNeighborhood">
                        <Form.Label>Bairro</Form.Label>
                        <Form.Control
                            disabled={props.isDisabled}
                            isInvalid={!!props.errors.addressNeighborhood}
                            onChange={props.handleChanges}
                            value={props.customer.addressNeighborhood}
                            name="addressNeighborhood"
                            maxLength={customerInputsLength.addressNeighborhood}
                            type="text"
                        />
                        {props.errors.addressNeighborhood && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.addressNeighborhood}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Col>

                <Col>
                    <Form.Group controlId="formBasicComplement">
                        <Form.Label>Complemento</Form.Label>
                        <Form.Control
                            disabled={props.isDisabled}
                            isInvalid={!!props.errors.addressComplement}
                            onChange={props.handleChanges}
                            value={props.customer.addressComplement}
                            name="addressComplement"
                            maxLength={customerInputsLength.addressComplement}
                            type="text"
                        />
                        {props.errors.addressComplement && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.addressComplement}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Col>
            </Row>
        </>
    );
};

export default CustomerAddress;
