import RequestV2 from './request-v2';

export default class CustomerHttpService {
    public static uriV2 = 'invoice/customer';

    public static readMany(params: {}) {
        return RequestV2.get(this.uriV2, params);
    }

    public static readOne(id: string) {
        return RequestV2.get(`${this.uriV2}/${id}`);
    }

    public static create(data: {}) {
        return RequestV2.post(this.uriV2, data);
    }

    public static update(id: string, data: {}) {
        return RequestV2.put(`${this.uriV2}/${id}`, data);
    }
}
